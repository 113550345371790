import { ForceAuthGuard } from "./guards/force-auth.guard";
import { SingleSalonGuard } from "./guards/single-salon.guard";
import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { GuestGuard } from "./guards/guest.guard";

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./pages/home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "home",
    redirectTo: "",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginPageModule),
    canActivate: [GuestGuard],
  },
  {
    path: "register",
    loadChildren: () => import("./pages/register/register.module").then((m) => m.RegisterPageModule),
    canActivate: [GuestGuard],
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./pages/forgot-password/forgot-password.module").then((m) => m.ForgotPasswordPageModule),
    canActivate: [GuestGuard],
  },
  {
    path: "salon/:slug",
    loadChildren: () => import("./pages/salon/salon.module").then((m) => m.SalonPageModule),
    canActivate: [SingleSalonGuard],
  },
  {
    path: "password/reset/:token",
    loadChildren: () => import("./pages/reset-password/reset-password.module").then((m) => m.ResetPasswordPageModule),
    canActivate: [GuestGuard],
  },
  {
    path: "profile",
    loadChildren: () => import("./pages/profile/profile.module").then((m) => m.ProfilePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "profile/details",
    loadChildren: () => import("./pages/details/details.module").then((m) => m.DetailsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "book-appointment/:service-id",
    loadChildren: () =>
      import("./pages/book-appointment/book-appointment.module").then((m) => m.BookAppointmentPageModule),
    canActivate: [SingleSalonGuard],
  },
  {
    path: "search",
    loadChildren: () => import("./pages/search-results/search-results.module").then((m) => m.SearchResultsPageModule),
  },
  {
    path: "search/:search",
    loadChildren: () => import("./pages/search-results/search-results.module").then((m) => m.SearchResultsPageModule),
  },
  {
    path: "search/:search/:location",
    loadChildren: () => import("./pages/search-results/search-results.module").then((m) => m.SearchResultsPageModule),
  },
  {
    path: "profile/appointments",
    loadChildren: () =>
      import("./pages/view-appointments/view-appointments.module").then((m) => m.ViewAppointmentsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "profile/settings",
    loadChildren: () => import("./pages/settings/settings.module").then((m) => m.SettingsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "cart",
    loadChildren: () => import("./pages/cart/cart.module").then((m) => m.CartPageModule),
  },
  {
    path: "profile/appointments/:booking-id",
    loadChildren: () =>
      import("./pages/view-appointment/view-appointment.module").then((m) => m.ViewAppointmentPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "checkout",
    loadChildren: () => import("./pages/checkout/checkout.module").then((m) => m.CheckoutPageModule),
    canActivate: [ForceAuthGuard],
  },
  {
    path: "gallery",
    loadChildren: () => import("./pages/gallery/gallery.module").then((m) => m.GalleryPageModule),
  },
  {
    path: "about-us",
    loadChildren: () => import("./pages/about-us/about-us.module").then((m) => m.AboutUsPageModule),
    data: {
      title: "About Us",
    },
  },
  {
    path: "available-appointments/:appointment-id",
    loadChildren: () =>
      import("./pages/available-appointments/available-appointments.module").then(
        (m) => m.AvailableAppointmentsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "404",
    loadChildren: () => import("./pages/not-found/not-found.module").then((m) => m.NotFoundPageModule),
  },
  {
    path: "profile/notifications",
    loadChildren: () => import("./pages/messages/messages.module").then((m) => m.MessagesPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "email/verify/:user-id/:token",
    loadChildren: () => import("./pages/verify-email/verify-email.module").then((m) => m.VerifyEmailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "categories/:slug",
    loadChildren: () => import("./pages/category/category.module").then((m) => m.CategoryPageModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  { path: "**", redirectTo: "404" },
  {
    path: 'related-service',
    loadChildren: () => import('./pages/related-service/related-service.module').then( m => m.RelatedServicePageModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
