import { Component, OnInit, Input } from '@angular/core';
import { Booking } from 'src/app/interfaces/booking';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { NotyfService } from './../../services/notyf/notyf.service';
import { LoadingController, NavController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';

const REFRESH_APPOINTMENTS_TIMEOUT = 5 * 60 * 1000; // 5 minutes

@Component({
  selector: 'app-cancelled-bookings',
  templateUrl: './cancelled-bookings.component.html',
  styleUrls: ['./cancelled-bookings.component.scss'],
})
export class CancelledBookingsComponent implements OnInit {

  @Input() salon_id: number = null;

  show_available_appointments:boolean = false;
  available_appointments: Booking[] = [];

  available_appointments_count = 0;

  available_appointment_index = 0;

  private appointments_sub;
  private appointments_timeout; 

  constructor(
    private api: ApiService,
    private env: EnvironmentService,
    private nav: NavController,
  ) { }

  ngOnInit() {
    this.refreshAvailableAppointments();
  }

  toggleAvailableAppointmentsModal(){

    if(this.show_available_appointments){
      this.show_available_appointments = false;
    }else{
      this.show_available_appointments = true;
    }

    console.log(this.available_appointments);
    
  }

  openCancelledApointment(id){

    this.nav.navigateForward('/available-appointments/' + id);

  }

  refreshAvailableAppointments() {
    if (this.env.api.salon_id === null && this.salon_id === null) {
      return;
    }

    let data = {};

    if (this.salon_id) {
      data['salon_id'] = this.salon_id;
    }

    if (this.appointments_sub) {
      this.appointments_sub.unsubscribe();
    }

    this.appointments_sub = this.api.get('/available-appointments', data).subscribe(
      response => {
        if (response['success']) {
          if (response['data'] != this.available_appointments) {
            this.available_appointments = response['data'];
            this.available_appointments_count = this.available_appointments.length;
          }

          this.appointments_sub.unsubscribe();
        }
      }
    );

    clearTimeout(this.appointments_timeout);

    this.appointments_timeout = setTimeout(() => this.refreshAvailableAppointments(), REFRESH_APPOINTMENTS_TIMEOUT);
  }


}
