import { GeolocationService } from './../services/geolocation/geolocation.service';
import { Router } from '@angular/router';
import { EnvironmentService } from './../services/environment/environment.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private token: string = null;

    constructor(
        private auth: AuthService,
        private env: EnvironmentService,
        private router: Router,
        private geolocation: GeolocationService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = this.auth.getAccessToken();
        const salon_id: string|number|null = this.env.api.salon_id || null;

        // if the token exists, the user is logged in
        // we are also checking the requested URL is the API url, as adding the token to other HTTP requests could cause them to fail
        if (request.url.indexOf(this.env.api.url) > -1 || request.url.indexOf(this.env.api.test_url) > -1 || request.url.indexOf(this.env.api.dev_url) > -1) {
            if (token) {
                // add the token to the request to avoid any unauthorised errors being thrown
                request = request.clone({
                    headers: request.headers.set('Authorization', 'Bearer ' + token)
                });
            }
    
            if (!token && this.token) {
                request = request.clone({
                    headers: request.headers.set('Authorization', 'Bearer ' + this.token)
                });
                this.token = null;
            }
    
            if (salon_id) {
                request = request.clone({
                    headers: request.headers.set('X-Salon-ID', String(salon_id))
                })
            }

            if (this.env.api.salon_id !== null && this.geolocation.hasPermission()) {
                const latLng = this.geolocation.latLng;
                
                if (latLng.latitude !== null && latLng.longitude !== null) {
                    request = request.clone({
                        setParams: {
                            latitude: String(latLng.latitude),
                            longitude: String(latLng.longitude),
                        },
                    })
                }
            }
        }
        
        // console.log({request});

        this.token = token;

        return next.handle(request);
    }
}
